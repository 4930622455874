<template>
    <v-bottom-navigation
        v-model="bottomNav"
        :value="true"
        fixed
        app
        height="75"
        color="white">
        <v-btn color="primary" text value="Surveys" to="/home" class="survey">
            <span>{{ $t('bottom_navigation.surveys') }}</span>
            <v-icon>apps</v-icon>
        </v-btn>

        <v-btn color="primary" text value="drafts" :to="{name: 'drafts'}" class="drafts">
            <span>{{ $t('bottom_navigation.drafts') }}</span>
            <v-icon>dns</v-icon>
        </v-btn>

        <v-btn color="primary" text value="pending" :to="{name: 'pending'}" class="pending">
            <span>{{ $t("bottom_navigation.pending") }}</span>
            <v-icon>history</v-icon>
        </v-btn>

        <v-btn color="primary" text value="completed" :to="{name: 'completed'}" class="completed">
            <span>{{ $t("bottom_navigation.completed") }}</span>
            <v-icon>check</v-icon>
        </v-btn>

        <v-btn text icon color="primary" value="notifications" :to="{name: 'notification-list'}">
            <span>{{ $t("bottom_navigation.notifications") }}</span>
            <v-badge
                :content="newNotifications.length"
                :value="newNotifications.length"
                overlap
            >
                <v-icon>
                    notifications_none
                </v-icon>
            </v-badge>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'BottomNavigation',
    data() {
        return {
            bottomNav: 'recent'
        };
    },
    computed: {
        ...mapGetters('notifications', ['newNotifications'])
    }
};
</script>

<style lang="scss">
.v-item-group.v-bottom-navigation .v-btn {
    min-width: 60px !important;

    &.surveys,
    &.drafts,
    &.pending {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    &.completed {
        padding-left: 8px !important;
        padding-right: 15px !important;
    }
}
</style>
