<template>
  <div class="app-view">
    <page-transition>
      <router-view></router-view>
    </page-transition>
    <bottom-navigation />
  </div>
</template>

<script>
import PageTransition from '../../components/Transitions/PageTransition';
import BottomNavigation from '../../components/Navigation/BottomNavigation';
export default {
    name: 'home',
    components: {PageTransition, BottomNavigation}
};
</script>
